import React from 'react'

const PreLoader = () => {
    return (
        <>
            {/* <div className="pl-box">

                <div className="pl">
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__dot"></div>
                    <div className="pl__text">Loading…</div>
                </div>

            </div> */}
        </>
    )
}

export default PreLoader
